.container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.bg-outline {
  background-image: url('../assets/img/outline.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.outline-bg {
  position: absolute;
  width: 100%;
  margin-top: 110px;
}

.content-position {
  width: 100%;
}

.img-class {
  width: 100%;
}

.banner {
  width: 100%;
  height: 400px;
  object-fit: contain;
  margin-top: 80px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}
.grid-item {
  background-color: #EAE1D6;
  border: 2px solid #AB9B84;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  width: 100%;
  height: 300px;
}

.company-container {
  width: 100%;
  height: 350px;
  padding: 30px;
}

.company-logo {
  width: 80%;
  height: 100%;
  object-fit: contain;
}

/* Home Overlay */
span.box1-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box1-a:hover span.box1-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box1-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box2-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box2-a:hover span.box2-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box2-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box3-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box3-a:hover span.box3-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box3-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box4-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box4-a:hover span.box4-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box4-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box5-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box5-a:hover span.box5-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box5-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box6-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box6-a:hover span.box6-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box6-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box7-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box7-a:hover span.box7-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box7-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box8-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box8-a:hover span.box8-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box8-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

span.box9-grey-overlay {
  background: rgba(171, 155, 132, 0.9);
  display: none;
  height: 300px;
  width: '100%';
  position: relative;
  bottom: 300px;
  margin-bottom: -300px;
}

.box9-a:hover span.box9-grey-overlay {
  display:block;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

span.box9-grey-overlay span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.details-container {
  padding: 40px;
  background-color: #EAE1D6;
}

.page2-container-1 {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: #EAE1D6;
}

.page2-container-2 {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: #D0C2B6;
}

.page2-container-3 {
  /* display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column; */
  background-color: #EAE1D6;
}

.page2-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}

.page3-container-1 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #EBE3DD;
}

.page3-container-2 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #D0C2B6;
}

.page3-container-3 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #EBE3DD;
}

.page3-container-4 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #D0C2B6;
}

.page3-container-5 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #EBE3DD;
}

.page3-container-6 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #D0C2B6;
}

.page3-container-7 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #EBE3DD;
}

.page3-container-8 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #D0C2B6;
}

.page3-container-9 {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #EAE1D6;
}


.page3-content {
  display: flex;
  justify-content: center;
  height: 40vh;
  flex-direction: column;
}

.page4-map-container {
  width: 100%;
  background-color: #EAE1D6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.page4-gmap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #D0C2B6;
}

.page4-form-container {
  width: 100%;
  background-color: #D0C2B6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.page4-input-color {
  color: #B0A599;
}

.page4-work-container {
  width: 100%;
  background-color: #EAE1D6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.fixed-page2 {
  width: 100%;
  overflow-x: hidden; 
}