.menu-bg {
  background-image: url('../../assets/img/outline/outline-2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.menu-title-mobile {
  font-family: 'Nexa-Regular';
  font-size: 30px;
  letter-spacing: normal;
}

.carousel-control-prev-icon,
  .carousel-control-next-icon{
    filter: invert(1);
  }