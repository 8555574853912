.logo {
  width: 180px;
}

.details-header-container {
  width: 100%;
  height: auto;
  width: 100%;
  z-index: 100;
}

.floatingBtn {
  /* background-image: url('../assets/img/reserve-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  text-align: center; */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floatingBtn span {
  font-family: 'Nexa-Bold';
  font-size: 14px;
}

.btn-reserve {
  background-color: rgba(203, 169, 106, 0.7) !important;
  border: 0px !important;
}

.home-header-container {
  background-color: black;
  /* height: 100vh; */
  height: auto;
  width: 100%;
  z-index: 100;
}

.page2-header-container {
  background-color: #154760;
  /* height: 100vh; */
  height: auto;
  width: 100%;
  z-index: 100;
}

.page3-header-container {
  background-color: #896559;
  /* height: 100vh; */
  height: auto;
  width: 100%;
  z-index: 100;
}

.page4-header-container {
  background-color: #4088B4;
  /* height: 100vh; */
  height: auto;
  width: 100%;
  z-index: 100;
}

.footer-container {
  background-color: #18211F;
  height: 200px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.get-in-touch {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

@media only screen and (max-width: 300px) {
  .get-in-touch {
    height: 200px;
  }
}

.sideBarContainer {
    width: 300px;
    text-align: right;
    padding: 40px;
    height: 100vh;
    /* position: fixed; */
    overflow-x: hidden;
    overflow-y: hidden;
}