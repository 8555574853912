.bg-image-banner {
  background-image: url('../../assets/img/links/banner-min.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.backDrops {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px
}

.header-visible {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5000;
  height: 150px;
}

.topContainer {
  position: relative;
  text-align: center;
}

.topCentered {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topCentered .btnTop {
  /* color: #8E7F60; */
  color: #fff;
  font-family: 'MyriadPro-Light';
  letter-spacing: 5px;
  font-size: 25px;
  width: 250px;
  border-radius: 20px;
  border-color: #fff;
  margin-bottom: 20px;
}

.topCentered .btnTop:hover {
  /* color: #8E7F60; */
  color: #fff !important;
  background-color: #8E7F60 !important;
  border-color: #8E7F60 !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
}

.img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}