.gridContainer {
  display: flex;

}

.gridInnerLeft {
  flex: 1.5;
}

.gridInnerMiddle {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
}

.gridInnerRight {
  flex: 1.5;
}

.imgContainer {
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered strong {
  color: #fff;
  font-family: 'SourceSansVariable';
  letter-spacing: 5px;
  font-size: 35px;
  line-height: 60px;
}