
@font-face {
  font-family: 'Nexa-Regular';
  src: url('./assets/fonts/Nexa-Regular.otf');
}

@font-face {
  font-family: 'Nexa-Light';
  src: url('./assets/fonts/Nexa-Light.otf');
}

@font-face {
  font-family: 'Nexa-Bold';
  src: url('./assets/fonts/Nexa-Bold.otf');
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./assets/fonts/MyriadPro-Regular.otf');
}

@font-face {
  font-family: 'MyriadPro-Light';
  src: url('./assets/fonts/MyriadPro-Light.otf');
}

@font-face {
  font-family: 'SourceSansVariable';
  src: url('./assets/fonts/SourceSansVariable-Roman.otf');
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'MyriadPro-Light' !important;
  touch-action: pan-x pan-y;
  background-color: #334057 !important;
}


