.about-bg {
  background-image: url('../../assets/img/outline/outline-1.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.image {
  width: 100%;
}

.title {
  font-family: 'Nexa-Light';
  font-size: 6vw;
  letter-spacing: 25px;
}

.title-bottom {
  font-family: 'Nexa-Light';
  font-size: 6vw;
  letter-spacing: 30px;
}

.paragraph-title-left {
  font-family: 'Nexa-Regular';
  font-size: 18px;
  text-transform: uppercase;
}

.paragraph-left {
  font-family: 'Nexa-Light';
  /* text-align: justify; */
  line-height: 3;
  text-transform: uppercase;
}

.paragraph-title-right {
  font-family: 'Nexa-Regular';
  font-size: 18px;
  text-transform: uppercase;
}

.paragraph-right {
  font-family: 'Nexa-Light';
  text-align: right;
  line-height: 3;
  text-transform: uppercase;
}

.border-width {
  border: 1px solid #000;
}

.img-slider {
  width: 400px;
  height: 250px;
  object-fit: cover;
}

.overflow {
  overflow-y: scroll;
}

/* Mobile */

.title-mobile {
  font-family: 'Nexa-Light';
  font-size: 40px;
  letter-spacing: 10px;
  text-align: center;
}

.paragraph-title-left-mobile {
  font-family: 'Nexa-Regular';
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.paragraph-left-mobile {
  font-family: 'Nexa-Light';
  /* text-align: justify; */
  line-height: 3;
  font-size: 14px;
  text-transform: uppercase;
}

.title-bottom-mobile {
  font-family: 'Nexa-Light';
  font-size: 40px;
  letter-spacing: 10px;
  text-align: center;
}

.paragraph-title-right-mobile {
  font-family: 'Nexa-Regular';
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.paragraph-right-mobile {
  font-family: 'Nexa-Light';
  /* text-align: justify; */
  line-height: 3;
  font-size: 14px;
  text-transform: uppercase;
}