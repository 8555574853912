.image-location {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.image-location-mobile {
  height: 300px;
  width: 100%;
  object-fit: cover;
}