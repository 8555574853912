.bg-image {
  background-image: url('../../assets/img/images/home.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.contact-bg {
  background-image: url('../../assets/img/outline/outline-3.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.contact-title {
  font-family: 'Nexa-Regular';
  font-size: 50px;
  letter-spacing: normal;
}

.form-input {
  font-family: 'Nexa-Regular';
  font-size: 25px;
  letter-spacing: normal;
}

.contact-h2 {
  font-family: 'Nexa-Light';
}

.contact-p-title {
  font-family: 'Nexa-Light';
  font-size: 25px;
}

.contact-table {
  font-family: 'Nexa-Light';
  font-size: 18px;
  line-height: 2;
}

.contact-footer {
  font-family: 'Nexa-Light';
  font-size: 16px;
  line-height: 3;
}

/* Mobile */
.contact-title-mobile {
  font-family: 'Nexa-Regular';
  font-size: 30px;
  letter-spacing: normal;
}

.form-input-mobile {
  font-family: 'Nexa-Regular';
  font-size: 16px !important;
  letter-spacing: normal;
}

.contact-h2-mobile {
  font-family: 'Nexa-Light';
  font-size: 25px;
  font-weight: bold;
}

.contact-h3-mobile {
  font-family: 'Nexa-Light';
  font-size: 20px;
  font-weight: bold;
}

.contact-p-title-mobile {
  font-family: 'Nexa-Light';
  font-size: 20px;
}

.contact-table-mobile {
  font-family: 'Nexa-Light';
  font-size: 14px;
  line-height: 2;
}

.contact-footer-mobile {
  font-family: 'Nexa-Light';
  font-size: 14px;
  line-height: 3;
}